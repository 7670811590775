<script>
import Avatar from "./user/avatar.vue";
import TransitionToggle from "../transitions/toggle.vue";
import {mapGetters} from "vuex";

export default {
    name: "implement-part-line",

    emits: ['edit', 'add', 'billable'],

    props: {
        item: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapGetters({
            locale: 'trans/locale',
        })
    },

    data: function () {
        return {
            open: false
        };
    },

    components: {TransitionToggle, Avatar},

    methods: {
        edit: function (item) {
            if (item) {
                this.$store.dispatch('implementPart/edit', item);
            }
            this.$emit('edit', item);
        },

        billable: function (item) {
            if (item) {
                this.$store.dispatch('implementPart/edit', item);
            }
            this.$emit('billable', item);
        },

        add: function (item) {
            if (item) {
                this.$store.dispatch('implementPart/parent', item);
            }
            this.$emit('add');
        },

        openChildren: function () {
            this.open = !this.open;
        },

        getFormattedDate: function (date, style = {dateStyle: 'short', timeStyle: 'short'}) {
            return date.printDate(this.locale, style);
        },

        shortInterval: function (start, end) {
            const startDate = new Date(start);
            const endDate = new Date(end);
            const endStyle = {};
            const sameYear = startDate.getFullYear() === endDate.getFullYear();
            const sameMonth = sameYear && startDate.getMonth() === endDate.getMonth();
            const sameDay = sameMonth && startDate.getDate() === endDate.getDate();

            if (!sameYear) {
                endStyle.year = 'numeric';
            }

            if (!sameMonth) {
                endStyle.month = '2-digit';
            }

            if (!sameDay) {
                endStyle.day = '2-digit';
            }

            if (
                !sameYear
                && !sameMonth
                && !sameDay
            ) {
                endStyle.dateStyle = 'short';
                endStyle.timeStyle = 'short';
            } else {
                if (!sameYear) {
                    endStyle.year = 'numeric';
                }

                if (!sameMonth) {
                    endStyle.month = '2-digit';
                }

                if (!sameDay) {
                    endStyle.day = '2-digit';
                }

                if (!endStyle.timeStyle) {
                    endStyle.hour = '2-digit';
                    endStyle.minute = '2-digit';
                }
            }

            let formattedEnd = this.getFormattedDate(end, endStyle);

            if (!endStyle.timeStyle && !endStyle.month) {
                formattedEnd = formattedEnd.replace(' ', '. ');
            }

            return this.getFormattedDate(start) + ' - ' + formattedEnd;

        },
    },
}
</script>

<template>
    <li class="line list-border position-relative">
        <div class="d-flex w-100 align-items-center">
            <div @click="openChildren" class="d-inline-flex flex-shrink-1 align-items-center justify-content-center cube-32 bg-primary bg-gradient text-white rounded-circle cursor-pointer">
                <i class="fas fa-calendar-alt"></i>
            </div>
            <div class="ms-2 flex-grow-1">
                <strong class="font-size-12">{{ item.name }}</strong>
                <span class="mx-2">|</span>
                <template v-if="item.start && item.end">
                        <span class="font-size-12">
                        <span>
                            <i class="fas fa-calendar-alt text-primary me-2"></i>
                        </span>
                        <strong>{{ shortInterval(item.start, item.end) }}</strong>
                    </span>
                    <span class="mx-2">|</span>
                </template>
                <template v-if="item.location">
                        <span>
                            <i class="fas fa-map-marker-alt text-primary me-2"></i>
                            <span>{{ item.location }}</span>
                        </span>
                    <span class="mx-2">|</span>
                </template>
                <template v-if="item.implementers?.length">
                    <div class="d-inline-block">
                        <i class="fas fa-graduation-cap text-primary me-2" :title="$tc('projects::implementers.implementer', item.implementers?.length).ucFirst()"></i>
                        <div class="d-inline-block avatar-group">
                            <template v-for="(implementer, iIndex) in item.implementers" :key="iIndex">
                                <avatar :user="implementer" size="24px" class="avatar-group-item"></avatar>
                            </template>
                        </div>
                    </div>
                    <span class="mx-2">|</span>
                </template>
                <template v-if="item.documenter">
                    <div class="d-inline-flex align-items-center">
                        <i class="fas fa-file-alt me-2"
                           :class="{'text-success': item.documented_at, 'text-danger': !item.documented_at}"
                           :title="$t('projects::implement_parts.documentation_not_completed').ucFirst()"
                        ></i>
                        <avatar :user="item.documenter" size="24px" class="avatar-group-item"></avatar>
                    </div>
                    <span class="mx-2">|</span>
                </template>
                <template v-if="item.notification">
                    <i class="text-primary fas fa-sticky-note" v-b-popover.hover.top="item.notification" ></i>
                    <span class="mx-2">|</span>
                </template>
                <span class="me-2">
                    <b-button :title="$tc('projects::products.product', 2).ucFirst()" @click="billable(item)" variant="soft-success" size="sm" class="cube-24 rounded-circle ratio-1x1">
                        <i class="fas fa-dollar-sign"></i>
                    </b-button>
                </span>
                <span>
                    <b-button :title="$t('base.edit').ucFirst()" @click="edit(item)" variant="soft-primary" size="sm" class="cube-24 rounded-circle ratio-1x1">
                        <i class="fas fa-edit"></i>
                    </b-button>
                </span>
            </div>
        </div>
        <transition-toggle>
            <div v-show="open">
                <ul class="list-unstyled mt-2 ps-4 list-border">
                    <template v-for="(part, partIndex) in item.implement_parts" :key="partIndex">
                        <implement-part-line class="mb-1" :item="part" @add="add" @edit="edit" @billable="billable"></implement-part-line>
                    </template>
                </ul>
                <span class="ps-4 mt-2">
                    <b-button class="rounded-circle" size="sm" variant="success" @click="add(item)">
                        <i class="fas fa-plus"></i>
                    </b-button>
                </span>
            </div>
        </transition-toggle>
    </li>
</template>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "@/assets/scss/_variables.scss";

.cube-32 {
    width: 32px;
    height: 32px;
}

.cube-24 {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.line {
    &:not(:last-of-type) {
        margin-bottom: 0.5rem;
    }
    &:hover {
        border-radius: 16px;
        background-color: lighten($warning, 25%);
    }
}
</style>