<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ title }}</h4>
                </div>
                <div class="card-body">
                    <implement-form :implement="implement" :own="own" :accessible="accessible" :interaction="interaction">
                        <template #buttons="{save}">
                            <callback-button
                                class="ms-2"
                                :method="save"
                                :callback="back"
                            ></callback-button>
                        </template>
                    </implement-form>
                </div>
            </div>
        </div>
<!--        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t('projects::implements.relationships.billables').ucFirst() }}</h4>
                </div>
                <div class="card-body bg-light">
                    <div class="row" v-if="implement.id">
                        <div class="col-lg-12">
                            <billable-items :id="implement.id" field="implement_id" store="billableImplement" :extra-fields="extraFields" :relations="['implementer', 'implementers', 'document']">
                                <template #implementers="{value}">
                                    <template v-if="value">
                                        <span v-for="(implementer, index) in value" :key="index" class="badge bg-white border-dark text-dark">
                                            <span>{{ implementer.name }}</span>
                                            <i class="ms-1 fas" :class="{'fa-check text-success': implementer?.pivot?.completed, 'fa-times text-danger': !implementer?.pivot?.completed}"></i>
                                        </span>
                                    </template>
                                </template>
                                <template #inputs="{states, errors, extra, setFile}">
                                    <div class="col-lg-1 mb-4" v-if="extra?.id && extra?.file_id">
                                        <div class="d-flex w-100 h-100 align-items-center justify-content-center">
                                            <file-private-viewer model="billableImplement" :id="extra.id" :file-id="extra.file_id">
                                                <template #default="{ click }">
                                                    <i class="fas fa-file-pdf font-size-26 text-primary" @click="click"></i>
                                                </template>
                                            </file-private-viewer>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 mb-4">
                                        <label
                                            :class="{'text-danger': states.file === false}"
                                            class="form-label"
                                            for="file"
                                        >{{ $t('projects::billable_implements.columns.file_id').ucFirst() }}</label>
                                        <file-input
                                            id="file_file"
                                            name="file_file"
                                            :class="{'border-danger': states.file === false}"
                                            :placeholder="$t('projects::billable_implements.relationships.file').ucFirst()"
                                            @change="setFile($event)"
                                        ></file-input>
                                        <div :class="{'d-block': states.file === false}" class="invalid-feedback">
                                            {{ errors.file }}
                                        </div>
                                    </div>
                                    <div class="col-lg-1 mb-4" v-if="extra?.file_link">
                                        <div class="d-flex w-100 h-100 align-items-center justify-content-center">
                                            <a :href="extra.file_link" target="_blank">
                                                <i class="fas fa-file-pdf font-size-26 text-primary"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 mb-4">
                                        <label
                                            :class="{'text-danger': states.file_link === false}"
                                            class="form-label"
                                            for="file_link"
                                        >{{ $t('projects::billable_implements.columns.file_link').ucFirst() }}</label
                                        >

                                        <input
                                            id="file_link"
                                            v-model="extra.file_link"
                                            :class="{'border-danger': states.file_link === false}"
                                            :placeholder="$t('projects::billable_implements.placeholders.file_link').ucFirst()"
                                            class="form-control"
                                            type="text"
                                        />
                                        <div :class="{'d-block': states.file_link === false}" class="invalid-feedback">{{ errors.file_link }}</div>
                                    </div>

                                    <div class="col-lg-12 mb-4">
                                        <label
                                            :class="{'text-danger': states.implementers === false}"
                                            class="form-label"
                                            for="users"
                                        >{{ $t('projects::billable_implements.relationships.implementers').ucFirst() }}</label>
                                        <multiple-select id="users" v-model="extra.implementers" store="user" action="implementers" value-field="implementer_id" pivot-store="billableImplementImplementer">
                                            <template #pivot-form="{ close, attach }">
                                                <billable-implement-implementer-form :model-value="billableImplementImplementer" :close="close" :attach="attach" :key="billableImplementImplementer.id"></billable-implement-implementer-form>
                                            </template>
                                        </multiple-select>
                                    </div>

                                    <div class="col-lg-3 mb-4">
                                        <label
                                            :class="{'text-danger': states.deadline === false}"
                                            class="form-label"
                                            for="deadline"
                                        >{{ $t('projects::billable_implements.columns.deadline').ucFirst() }}</label
                                        >
                                        <input
                                            id="deadline"
                                            v-model="extra.deadline"
                                            :class="{'border-danger': states.deadline === false}"
                                            :placeholder="$t('projects::billable_implements.placeholders.deadline').ucFirst()"
                                            class="form-control"
                                            type="datetime-local"
                                        />
                                        <div :class="{'d-block': states.deadline === false}" class="invalid-feedback">
                                            {{ errors.deadline }}
                                        </div>
                                    </div>

                                    <div class="col-lg-3 mb-4">
                                        <label
                                            :class="{'text-danger': states.realized_at === false}"
                                            class="form-label"
                                            for="realized_at"
                                        >{{ $t('projects::billable_implements.columns.realized_at').ucFirst() }}</label
                                        >
                                        <input
                                            id="realized_at"
                                            v-model="extra.realized_at"
                                            :class="{'border-danger': states.realized_at === false}"
                                            :placeholder="$t('projects::billable_implements.placeholders.realized_at').ucFirst()"
                                            class="form-control"
                                            type="datetime-local"
                                        />
                                        <div :class="{'d-block': states.realized_at === false}" class="invalid-feedback">
                                            {{ errors.realized_at }}
                                        </div>
                                    </div>
                                </template>
                            </billable-items>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
    </div>
    <!-- end row -->
</template>

<script>

import {mapGetters} from "vuex";
import CallbackButton from "../../../components/elements/callback-button.vue";
import ImplementForm from "../../../components/forms/implement-form.vue";
/*import BillableItems from "../../../components/elements/billable-items.vue";
import MultipleSelect from "../../../components/elements/multiple-select.vue";
import FileInput from "../../../components/elements/file-input.vue";
import FilePrivateViewer from "../../../components/elements/file -private-viewer.vue";
import BillableImplementImplementerForm from "../../../components/forms/billable-implement-implementer-form.vue";*/

export default {
    props: {
        own: {
            type: Boolean,
            default: false
        },

        accessible: {
            type: Boolean,
            default: false
        },

        interaction: {
            type: Boolean,
            default: false
        }
    },

    components: {
        /*BillableImplementImplementerForm,
        FilePrivateViewer, FileInput, MultipleSelect, BillableItems,*/ ImplementForm, CallbackButton},

    computed: {
        ...mapGetters({
            implement: 'implement/item',
            implementers: 'user/implementers',
            locale: 'trans/locale',
            billableImplementImplementer: 'billableImplementImplementer/item'
        }),

        title: function () {
            return this.implement.id ? this.$tc('projects::implements.implement', 2).ucFirst() + ' / ' + (this.implement.project?.translation_key ? this.$t(this.implement.project.translation_key).ucFirst() :this.implement.project?.name) : this.$t('base.create_new_item', {item: this.$tc('projects::implements.implement', 1)}).ucFirst()
        },

        link: function () {
            return {path: this.$t('routes.' + '/implements')}
        },

        extraFields: function () {
            return [
                {
                    key: 'implementers',
                    label: this.$tc('projects::implementers.implementer', 2).ucFirst(),
                    formatter: (value) => {
                        return value?.map(item => item?.name).join(', ');
                    }
                },
                {
                    key: 'deadline',
                    label: this.$t('projects::billable_implements.columns.deadline').ucFirst(),
                    formatter: (value) => value?.printDate(this.locale),
                },
                {
                    key: 'realized_at',
                    label: this.$t('projects::billable_implements.columns.realized_at').ucFirst(),
                    formatter: (value) => value?.printDate(this.locale),
                },
            ]
        },
    },

    methods: {
        back: function () {
            this.$router.go(-1);
            // this.$router.push(this.link)
        },
    },

    watch: {
        title: function (value) {
            this.$store.dispatch('app/title', {key: value})
        },
    },

    mounted() {
        if (this.$route.params.id) {
            this.$store.dispatch('implement/show', {id: this.$route.params.id, query: { with: ['billables', 'project', 'contracts', 'contracts.billables', 'implementParts', 'implementParts.billables', 'implementParts.documenter', 'implementParts.implementers',]}})
        }
    },

    unmounted() {
        this.$store.dispatch('implement/clearItem')
        this.$store.dispatch('implementer/all')
        this.$store.dispatch('app/title', '')
    }

}
</script>

<style scoped>

</style>
